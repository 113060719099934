<template>
    <div>
      <!-- <div class="btn">
        <div class="btn_left" @click="err">取消</div>
        <div class="btn_right" @click="send">确定</div>
      </div> -->
      <!-- <div class="select">
        <div>
          <select name id @change="lunary" ref="lunary" class="select_y" size="5">
            <option v-for="(item,index) in ylist" :key="index" selected>{{item}}</option>
          </select>
        </div>
        <div>
          <select name id @change="lunarm" ref="lunarm" class="select_m" size="5">
            <option v-for="(item,index) in mlist" :key="index">{{item}}</option>
          </select>
        </div>
        <div>
          <select name id @change="lunard" ref="lunard" class="select_d" size="5">
            <option v-for="(item,index) in dlist" :key="index">{{item}}</option>
          </select>
        </div>
      </div> -->
      <div class="lunar-converter">
         <el-row class="input-row">
              <label class="label">阴历年份：</label>
              <el-select filterable v-model="value1" placeholder="阴历年份" @change="lunary">
                  <el-option  v-for="item in ylist"
                  :key="item"
                  :label="item"
                  :value="item" 
                  ></el-option>
              </el-select>
          </el-row>
          <el-row class="input-row">
              <label class="label">阴历月份：</label>
              <el-select v-model="value2" placeholder="阴历月" @change="lunarm">
              <el-option
                v-for="item in mlist"
                :key="item"
                :label="item"
                :value="item"
                >
              </el-option>
          </el-select>
          </el-row>
          <el-row class="input-row">
              <label class="label">阴历日期：</label>
              <el-select v-model="value3" placeholder="阴历日" @change="lunard">
                <el-option
                  v-for="item in dlist"
                  :key="item"
                  :label="item"
                  :value="item"
                  >
                </el-option>
             </el-select>
          </el-row>
          <el-row class="input-row">
              <label class="label">小时：</label>
              <el-select v-model="value4" placeholder="小时" @change="lunard">
            <el-option
              v-for="item in hours"
              :key="item"
              :label="item"
              :value="item"
              >
          </el-option>
          </el-select>
          </el-row>
          <el-row class="input-row">
              <label class="label">分钟：</label>
              <el-select v-model="value5" placeholder="分钟" @change="lunard">
                <el-option
                  v-for="item in minute"
                  :key="item"
                  :label="item"
                  :value="item"
                  >
              </el-option>
          </el-select>
          </el-row>
        <!-- <div class="nongli_flex2">
          <div class="nongli_flex2_title">阴历年份：</div>
          <el-select v-model="value1" placeholder="阴历年"  @change="lunary">
            <el-option
              v-for="item in ylist"
              :key="item"
              :label="item"
              :value="item"
              >
            </el-option>
          </el-select>
        </div> -->
       
       <!-- <div class="nongli_flex2">
          <div class="nongli_flex2_title">阴历月份：</div>
          <el-select v-model="value2" placeholder="阴历月" @change="lunarm">
            <el-option
              v-for="item in mlist"
              :key="item"
              :label="item"
              :value="item"
              >
            </el-option>
          </el-select>
       </div> -->
        <!-- <div class="nongli_flex2">
          <div class="nongli_flex2_title">阴历日期：</div>
          <el-select v-model="value3" placeholder="阴历日" @change="lunard">
            <el-option
              v-for="item in dlist"
              :key="item"
              :label="item"
              :value="item"
              >
            </el-option>
          </el-select>
        </div> -->
        <!-- <div class="nongli_flex2">
          <div class="nongli_flex2_title">小时：</div>
          <el-select v-model="value4" placeholder="小时" @change="lunard">
            <el-option
              v-for="item in hours"
              :key="item"
              :label="item"
              :value="item"
              >
          </el-option>
          </el-select>
        </div> -->
        <!-- <div class="nongli_flex2">
          <div class="nongli_flex2_title">分钟：</div>
          <el-select v-model="value5" placeholder="分钟" @change="lunard">
            <el-option
              v-for="item in minute"
              :key="item"
              :label="item"
              :value="item"
              >
          </el-option>
          </el-select>
        </div> -->
      </div>
    </div>
  </template>
  <script>
  import {
    GetLunarDateString,
    GetSolarDateString,
    SolarYearDays,
    SolarMonthDays,
    LunarYearDays,
    LunarMonthDays,
    GetLeapMonth,
    FormatLunarMonth,
    FormatLunarDay,
    FormatLunarYear,
  } from "./lucnDate.js";

  export default {
    data() {
      return {
        ylist: null,
        mlist: null,
        dlist: null,
        data: {},
        value1:"",
        value2:"",
        value3:"",
        value4:"",
        value5:"",
        hours:[],
        minute:[]
      };
    },
    methods: {
      populateHours() {
            for (let hour = 0; hour <= 23; hour++) {
                this.hours.push(Number(hour));
            }
        },
        populateMinute(){
          for (let minute = 0; minute <= 59; minute++) {
                this.minute.push(Number(minute));
            }
        },
      // 取消
      err() {
        // 向父组件传值
        this.$emit("func", this.data);
      },
      // 确认
      send() {
         
        // if (
        //   this.value1 && this.value2 && this.value3 && this.value4.toString() && this.value5.toString()
          
        // ) {
          
         let data = {
            lunary: this.value1,
            lunarm: this.value2,
            lunard: this.value3,
            hours: this.value4,
            minutes: this.value5,
          };
          // console.log('this.data',data);
          this.$emit("func", data);
        // } else {
        //   this.$message('请选择完整日期！')
        // }
      },
      // 年
      lunary() {
        // console.log('年');
        // this.mlist = this.lunarMonthOptions(this.$refs.lunary.value);
        this.mlist = this.lunarMonthOptions(this.value1);
        // 从新选择年再次计算
        this.lunarm();
      },
      // 月
      lunarm() {
        // console.log('月');
        // 将农历月转化为公历月
        // var disLength = this.$refs.lunarm.value.length;
        // var shortName = this.$refs.lunarm.value.substring(disLength - 2, disLength);

        var disLength = this.value2.length;
        var shortName = this.value2.substring(disLength - 2, disLength);
          switch (shortName) {
            case "正月":
              this.m = 1;
              break;
            case "二月":
              this.m = 2;
              break;
            case "三月":
              this.m = 3;
              break;
            case "四月":
              this.m = 4;
              break;
            case "五月":
              this.m = 5;
              break;
            case "六月":
              this.m = 6;
              break;
            case "七月":
              this.m = 7;
              break;
            case "八月":
              this.m = 8;
              break;
            case "九月":
              this.m = 9;
              break;
            case "十月":
              this.m = 10;
              break;
            case "冬月":
              this.m = 11;
              break;
            case "腊月":
              this.m = 12;
              break;
            default:
          }
        // 输入当年当月的天数
        // this.dlist =  this.lunarDayOptions(this.$refs.lunary.value, this.m);
        this.dlist =  this.lunarDayOptions(this.value1, this.m);
        this.send()
      },
      // 天
      lunard() {
        // console.log('日e');
        //$("select").on("blur", function() {
        //  window.scroll(0, 0); //失焦后强制让页面归位
        //});
        this.send()
      },
      // 循环年
      lunarYearOptions(from, to, curyear) {
        var str = [];
        for (var i = from; i <= to; i++) {
          str.push(i);
          // str.push(FormatLunarYear(i) + "(" + i + ")");
        }
        return str;
      },
      // 循环月
      lunarMonthOptions(year, month) {
        var leapMonth = GetLeapMonth(year);
        var str = [];
        var from = 1;
        var to = 12;
  
        var leap = false;
        if (month > 12) {
          leap = true;
          month = month / 10;
        }
        for (var i = from; i <= to; i++) {
          str.push(FormatLunarMonth(i));
          if (leapMonth > 0 && i == leapMonth) {
            str.push("闰" + FormatLunarMonth(i));
          }
        }
        return str;
      },
      // 循环天
      lunarDayOptions(year, month, day) {
        // console.log(year, month, day)
        var from = 1;
        var to = 0;
        var leap = false;
        let monthdaynum=null;
        if (month > 12) {
          month = month / 10;
          leap = true;
          monthdaynum = (LunarMonthDays(year, month) >> 16) & 0xffff;
        } else {
          monthdaynum = LunarMonthDays(year, month) & 0xffff;
        }
        to = monthdaynum;
        // console.log(to)
        var str = [];
        for (var i = from; i <= to; i++) {
          str.push(FormatLunarDay(i));
        }
        return str;
      }
    },
    mounted() {
      // //设定当前农历公历日期
      var curSolarDate = [2024, 5, 26];
      // 年,月，日
      var curLunarDate = GetSolarDateString(curSolarDate);
      console.log('curLunarDate',curLunarDate);
      this.ylist = this.lunarYearOptions(1902, 2049, curLunarDate[0]);
      this.mlist = this.lunarMonthOptions(
        curLunarDate[0],
        curLunarDate[1],
        curLunarDate[2]
      );
       this.populateHours()
       this.populateMinute()
    }
  };
  </script>
  <style scoped>
  .btn {
    height: 0.3rem;
    line-height: 1rem;
    font-size: 0.29rem;
  }
  .btn_left {
    float: left;
    padding-left: 0.1rem;
    color: #1989fa;
  }
  .btn_right {
    float: right;
    padding-right: 0.1rem;
    color: #1989fa;
  }
  .select {
    margin-top: 1rem;
    width: 100%;
    /* text-align: center; */
    display: flex;
    justify-content: space-around;
    overflow-y: hidden;
  }
  .select_y option {
    /* width: 33%; */
    /*去掉默认的下拉三角*/
    /* appearance: none; */
    height: 0.7rem;
    padding: 0.2rem 0;
  }
  .select_m option {
    /* width: 33%; */
    height: 0.7rem;
    padding: 0.2rem 0;
  }
  .select_d option {
    /* width: 33%; */
    height: 0.7rem;
    padding: 0.2rem 0;
  }
  </style>
  